@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Oswald", sans-serif;
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.breadcrumb-wrapper {
  margin-bottom: 40px;

  .breadcrumb {
    border-radius: 0;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    background: none;
    margin-bottom: 0;

    .breadcrumb-item {
      font-size: 11px;
      font-weight: 400;

      a {
        color: @color1;
        transition: all 0.4s;

        svg {
          display: none;
        }

        &:hover {
          color: darken(@color1, 15%);
        }
      }

      &.active {
        color: @color2;
        font-weight: 600;
      }
    }

    .breadcrumb-item + .breadcrumb-item::before {
      font-weight: 200;
    }
  }
}

.products-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;

    &.relative {
        position: relative;

        .category-img-absolute {
            position: absolute;
            width: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,60%);
            opacity: 0.5;
        }
    }

  .product-service {
    margin-top: calc(8% / 3);
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    text-align: center;

    .badge-wrapper {
      position: absolute;
      z-index: 111;
      top: 0;
      right: 0;

      .badge {
        width: 70px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 5px;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;

        &.promotion {
          background: tomato;
          display: none;
        }

        &.new {
          background: olivedrab;
          display: none;
        }

        &.bestseller {
          background: dodgerblue;
          display: none;
        }
      }
    }

    .service-image-wrapper {
      width: 100%;
      padding-top: 70%;
      position: relative;
      border-radius: 3px;
      display: block;
      transition: all 0.4s;
      background: #fff;
      position: relative;

      .service-image {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 7px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      &.active {
        &:after {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(211, 14, 14, 1);
          box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
        }
      }
    }

    .name {
      margin-top: 20px;
      color: #222;
      font-weight: 600;
      font-size: 14.5px;
      display: block;
      transition: all 0.4s;

      &.list-view {
        display: none;
      }
    }

    .type {
      margin-top: 5px;
      font-size: 11px;
      font-weight: 500;
      color: #777;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .price {
      margin-top: 10px;
      color: @color1;
      font-size: 22px;
      font-weight: 600;

      small {
        font-size: 15px;
        font-weight: 600;
        color: #444;
        margin-right: 5px;
        position: relative;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 1px;
          width: 100%;
          height: 1px;
          background: #999;
          transform: rotate(-10deg);
        }
      }

      span {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
    }

    .price-small {
      font-size: 13px;
      color: #999;
    }

    .btn-more {
      margin-top: 17px;
      width: 100%;
      display: flex;
      align-items: center;

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #fff;
        border-radius: 3px;
        transition: all 0.4s;

        &:nth-child(1) {
          min-width: 25px;
          border-right: 1px solid #f4f4f4;
          border-radius: 0;
          padding-right: 10px;
        }

        &:nth-child(2) {
          width: 100%;
          margin-left: 7px;
        }
      }
		}

    &.promotion {
      .promotion {
        display: block !important;
      }

      .price {
        color: tomato!important;

        small {
          display: inline-block;
        }
      }
    }

    &.new {
      .new {
        display: block !important;
			}

			.price {
				color: olivedrab;
			}
    }

    &.bestseller {
      .bestseller {
        display: block !important;
			}
			
			.price {
				color: dodgerblue;
			}
    }

    &:hover {
      .service-image-wrapper {
        opacity: 0.8;
      }

      .name {
        color: @color2;
      }

      .btn-more {
        background-color: #000;
        p {
          color: #fff;
        }
      }
    }

    &.service-hidden {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.grid-list {
    .product-service {
      width: 100% !important;
      flex-direction: row;
      justify-content: flex-start;

      header {
        min-width: 17%;
        margin-right: 15px;
      }

      .badge-wrapper {
        right: auto;
        left: 0;
        top: 10px;
      }

      .service-image-wrapper {
        &.active {
          &:after {
            left: auto;
            right: 0;
          }
        }
      }

      .price {
        position: absolute;
        right: 0;
        top: 30px;
        margin: 0;
      }

      .price-small {
        position: absolute;
        right: 0;
        top: 55px;
        margin: 0;
      }

      .name {
        margin-top: 0;

        &.grid-view {
          display: none;
        }

        &.list-view {
          display: block;
        }
      }

      &:not(:first-child) {
        padding-top: 30px;
        border-top: 1px solid #f5f5f5;
      }
    }
  }

  &.full-width {
    margin-top: calc(-5% / 2);

    .product-service {
      margin-top: calc(5% / 2);
      width: 22%;
    }
  }
}

.pagination-nav {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      border-radius: 3px;
      color: #777;
      text-transform: uppercase;
      transition: all 0.3s;
    }

    &:hover {
      a {
        color: @color1;
      }
    }

    &.active {
      a {
        font-weight: 700;
        color: #fff;
        background: @color1;
      }
    }
  }
}

.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;

  .radio-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.8px;
    font-weight: 300;
    text-align: left;
    transition: all 0.3s;

    &:before {
      content: "";
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      display: block;
      background: url("../img/icons/checkbox.svg") no-repeat;
      margin-right: 10px;
      transition: all 0.3s;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    width: auto !important;
  }
}

.checkbox-wrapper > input:checked + .radio-box {
  color: @color1;
  font-weight: 500;

  &:before {
    background: url("../img/icons/checkbox-checked.svg") no-repeat;
  }
}

.select-wrapper {
  position: relative;
  min-width: 180px;
  border-radius: 3px;
  border: 0.5px solid @color1;

  select {
    cursor: pointer;
    padding: 7px 10px;
    border: none;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    display: block;
    font-size: 12px;
    color: #000;
    font-weight: 500;
    letter-spacing: 1px;
    width: 100%;
  }

  &:after {
    cursor: pointer;
    content: "";
    height: 5px;
    width: 5px;
    background: url("../img/icons/down-chevron.svg") center;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-40%);
  }
}

.modal {
  img {
    width: 100%;
  }
}

.btn {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;

  &.btn-default {
    padding: 12px 80px;
    background-color: @color1;

    &:hover {
      background-color: darken(@color1, 10%);
      color: #fff;
    }
  }

  &.btn-more {
    padding: 10px 15px;
    background-color: @color1;

    &:hover {
      background-color: darken(@color1, 10%);
      color: #fff;
    }
  }
}

@color1: #022127;
@color2: #e93333;

nav.section-header {
  .header-top {
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-logo {
      display: flex;

      &.mobile {
        display: none;
      }

      p {
        font-size: 48px;
        color: #000;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
      }

      img {
        margin-right: 10px;
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color2;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }

    .top-service {
      display: flex;
      align-items: center;
    }

    .top-navigation {
      display: flex;
			align-items: center;
			
			&.mobile {
				display: none;
			}

      .divider {
        width: 1px;
        height: 25px;
        margin: 0 20px;
        display: block;
        background-color: #ccc;
      }

      li {
        display: flex;
        margin: 0 12px;

        &:first-child {
          img {
            width: 18px;
          }
        }

        a {
          font-size: 13px;
          color: #000;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.4s;

          p {
            font-size: 10px;
          }

          &:hover {
            color: @color2;
          }
        }

        img {
          margin-right: 10px;
          width: 25px;
        }
      }
    }
  }

  .header-management-wrapper {
    background-color: #f2f4f4;
  }

  .header-management {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &.mobile {
      display: none;
    }

    .management-search-engine {
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-wrapper {
        width: 250px;

        form {
          height: 100%;
          display: flex;

          .btn-search {
            border: 0;
            outline: none;
            background: transparent;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            img {
              margin-right: 10px;
            }
          }
        }

        .sb-search {
          position: relative;
          width: 100%;
          min-width: 40px;
          height: 40px;

          &:before {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1.5px;
            background-color: #000;
          }

          .focus-border {
            position: absolute;
            z-index: 1;
            display: block;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1.5px;
            background-color: @color2;
            transition: 0.4s;
          }

          &:focus {
            outline: none;
            border-bottom: 1px solid @color1;
          }

          .sb-search-input {
            border: none;
            outline: none;
            background: none;
            width: 100%;
            height: 100%;
            padding: 0 0 10px 0;
            border-radius: 0;
            color: #000;
            opacity: 0.6;
            transition: all 0.4s;

            &::placeholder {
              font-size: 15px;
              font-weight: 300;
            }

            &:focus {
              ~ .focus-border {
                width: 100%;
                transition: 0.4s;
              }
            }
          }
        }
      }
    }
  }

  .header-flexbox {
    position: relative;

    .flexbox-nav-wrapper {
      padding: 10px 0 20px 0;

      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            text-align: center;

            &.nav-link-mobile {
              display: none;

              &:last-of-type {
                a {
                  font-weight: 600;
                }
              }
            }

            a {
              font-size: 14px;
              transition: all 0.4s;
              color: #000;
              font-weight: 600;

              &:hover {
                color: @color2;
              }
            }

            &.contact {
              padding-top: 20px;
              display: none;

              a {
                color: @color2;
                font-weight: 500;
              }
            }

            &.active {
              a {
                font-weight: 600;
                color: @color2;
              }
            }

            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

.welcome-wrapper {
  background-color: #f2f4f4;
}

.main-section-heading {
  background-color: #f2f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .heading-content {
    position: relative;
    margin-left: 100px;
    width: 55%;

    .color-point {
      position: absolute;
      left: -100px;
      height: 100%;
      width: 70px;
      background: linear-gradient(
        167deg,
        rgba(255, 51, 51, 1) 15%,
        rgba(195, 51, 51, 1) 84%
      );
    }

    h1 {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 15px;
      font-weight: 400;
    }

    p {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .heading-image {
    position: relative;
    top: 30px;
    width: 45%;
    padding-top: 25%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

.section-heading {
  text-align: center;

  h2 {
    font-size: 48px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    padding: 0 10%;
    color: #777;
  }

  .btn-default {
    margin-top: 40px;
  }
}

section.section-welcome {
  padding-bottom: 40px;
  overflow: hidden;
}

section.static-page-section-content {
  padding-bottom: 60px;

  .section-description {
    margin-top: 80px;

    p {
      color: #777;
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;

      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    ul {
      padding: 10px 0 0 20px;

      li {
        padding: 8px 0;
        color: #777;
        font-weight: 300;
        font-size: 18px;
      }
    }
  }
}

section.section-offer {
  .section-heading {
    padding-top: 70px;

    &:first-of-type {
      padding-top: 30px;
    }
  }

  .offer-content {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;

    .category {
      width: calc(100% / 7 - 15px);
      height: 200px;
      background-color: #f2f4f4;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      transition: background-color 350ms;
      padding: 0 15px;

      h3 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        color: #000;
        transition: color 350ms;
      }

      &:hover {
        background-color: darken(#f2f4f4, 4%);

        h3 {
          color: @color2;
        }
      }
    }
  }
}

section.section-bestseller {
  padding-top: 70px;

  .owl-carousel-products {
    height: 450px;

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
    }

    .item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .badge-wrapper {
        position: absolute;
        z-index: 111;
        top: 0;
        right: 0;

        .badge {
          width: 100px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-bottom: 5px;
          color: #fff;
          font-size: 12px;
          border-radius: 3px;

          &.promotion {
            background: tomato;
            display: none;
          }

          &.new {
            background: olivedrab;
            display: none;
          }

          &.bestseller {
            background: dodgerblue;
            display: none;
          }
        }
      }

      &.promotion {
        .promotion {
          display: flex !important;
        }

        .price {
          color: tomato !important;

          small {
            display: inline-block !important;
          }
        }
      }

      &.new {
        .new {
          display: flex !important;
        }

        .price {
          color: olivedrab;
        }
      }

      &.bestseller {
        .bestseller {
          display: flex !important;
        }

        .price {
          color: dodgerblue;
        }
      }

      .product-image {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;

        img {
					width: 100%;
					height: 100%;
					object-fit: contain;
        }
      }

      .product-description {
        width: 45%;
        height: 100%;
        background-color: #f4f4f4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;

        h3 {
          font-size: 48px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
          font-weight: 300;
          color: #777;
        }

        .price {
          font-family: "Oswald", sans-serif;
          color: @color1;
          font-size: 48px;
          font-weight: 400;

          small {
            font-size: 22px;
            font-weight: 600;
            color: #444;
            margin-right: 5px;
            position: relative;
            display: none;

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: -3px;
              width: 115%;
              height: 1px;
              background: #aaa;
              transform: rotate(-10deg);
            }
          }

          span {
            font-weight: 400;
            color: #999;
            font-size: 12px;
          }
        }

        .price-small {
          font-size: 13px;
          color: #999;
        }

        .btn-default {
          margin-top: 50px;
        }
      }
    }
  }
}

section.section-cooperate {
  padding-top: 70px;

  .owl-carousel {
    margin: 40px 0;

    .owl-stage {
      display: flex;
      align-items: center;
    }

    .owl-controls {
      display: none;
    }
  }
}

section.kategoria-section-content {
  padding: 0 0 35px 0;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-sidebar {
      width: 20%;
      position: relative;

      .heading {
        margin-bottom: 15px;

        h2 {
          font-size: 20px;
          color: #000;
          letter-spacing: 0.8px;
          font-weight: 500;
        }
      }

      .nav-button {
        outline: none;
        padding: 10px;
        width: 100%;
        border: none;
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
        background: @color1;
        transition: all 0.3s;
        display: none;
        cursor: pointer;
        position: relative;
        border-radius: 3px;

        &:hover {
          background: @color1;
        }
      }

      .category-nav-wrapper {
        position: relative;

        &.active {
          opacity: 1;
          visibility: visible;
        }

        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: 0;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .category-nav-inner {
          .category-nav {
            > li > ul {
              margin-top: 5px;
            }

            li {
              list-style: none;

              a {
                font-size: 15px;
                color: #000;
                letter-spacing: 0.8px;
                font-weight: 300;
                transition: all 0.4s;
                display: block;

                &:hover {
                  color: @color2;
                }
              }

              &.active {
                > a {
                  font-weight: 500;
                  color: @color2;
                }
              }

              &:not(:last-child) {
                padding-bottom: 10px;
              }

              ul {
                padding-left: 17px;

                li {
                  padding-bottom: 0 !important;
                  border-bottom: none !important;
                  margin-bottom: 0 !important;

                  a {
                    padding: 0.25rem 0;
                    font-size: 0.7rem;
                    letter-spacing: 0.5px;
                    color: #777;
                  }
                }
              }
            }
          }

          .filter-nav {
            margin-top: 30px;

            .heading {
              &:not(:first-of-type) {
                margin-top: 30px;
              }
            }

            .range-slider {
              width: 100%;
              margin: auto;
              margin-top: 20px;
              text-align: center;
              position: relative;

              .slider-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                input[type="number"] {
                  border: none;
                  margin: 0 10px;
                  outline: none;
                  text-align: center;
                  font-size: 14px;
                  height: 30px;
                  border: 1px solid @color1;
                  border-radius: 3px;
                  width: 100%;
                  max-width: 65px;
                  -moz-appearance: textfield;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }

                input[type="number"]:invalid,
                input[type="number"]:out-of-range {
                  border: 1px solid #ff6347;
                }

                span {
                  display: block;
                  min-width: 12px;
                }

                p {
                  font-size: 13px;
                }
              }
            }

            .select-wrapper {
              margin-top: 20px;
            }

            .checkbox-wrapper {
              cursor: pointer;
              display: block;
              margin-bottom: 0;

              .radio-box {
                display: flex;
                align-items: center;
                font-size: 13px;
                text-transform: none;
                color: #000;
                text-align: left;
                transition: all 0.3s;
                padding: 5px 0;
                font-weight: 500;

                &:before {
                  content: "";
                  min-width: 16px;
                  max-width: 16px;
                  height: 16px;
                  display: block;
                  background: url("../img/icons/checkbox.svg") no-repeat;
                  margin-right: 10px;
                  transition: all 0.3s;
                }

                p,
                span {
                  font-size: 11px;
                }
              }

              input {
                visibility: hidden;
                position: absolute;
                width: auto !important;
              }
            }

            .checkbox-wrapper > input:checked + .radio-box {
              font-weight: 700;

              &:before {
                background: url("../img/icons/checkbox-checked.svg") no-repeat;
              }
            }

            .buttons-wrapper {
              margin-top: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn-clear {
                color: #000;
              }
            }
          }
        }
      }
    }

    .flexbox-body {
      width: 72%;

      .body-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .heading-text {
          font-size: 11px;
          color: #000;
          letter-spacing: 0.8px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .inner-wrapper {
          display: flex;
          align-items: center;

          .heading-grid {
            margin-left: 30px;
            display: flex;

            a,
            button {
              margin-right: 7px;
              padding: 0;
              border: none;
              background: #fff;
              border-radius: 3px;
              outline: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              transition: all 0.4s;

              img {
                width: 15px;
                transition: all 0.4s;
              }

              &:last-child {
                margin-right: 0;
              }

              &.active {
                background: @color1;
              }
            }
          }
        }
      }

      .heading {
        font-family: "Oswald", sans-serif;
        margin-top: 70px;
        margin-bottom: 30px;
        text-align: center;
        color: #000;
        font-weight: 400;
        font-size: 48px;
      }

      .body-product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .product-gallery {
          width: 30%;
          position: relative;
          margin-top: 50px;

          img {
            width: 100%;
            border-radius: 3px;
          }
        }

        .product-info {
          width: 60%;

          .badge-wrapper {
            display: flex;
            z-index: 111;

            .badge {
              font-weight: 700;
              font-size: 10px;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: #fff;
              width: 90px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              border-radius: 3px;
              transition: all 0.4s;
              margin-bottom: 35px;

              &.promotion {
                background: tomato;
                display: none;
              }

              &.new {
                background: olivedrab;
                display: none;
              }

              &.bestseller {
                background: dodgerblue;
                display: none;
              }

              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }

          h1 {
            color: #000;
            font-weight: 400;
            font-size: 48px;
            line-height: 48px;
            position: relative;
          }

          .small-description {
            margin-top: 30px;

            p,
            li {
              font-size: 12px;
              color: #777;
              font-weight: 300;
              line-height: 22px;

              strong,
              b {
                font-weight: 700;
              }
            }

            ul,
            ol {
              padding-left: 30px;
            }
          }

          .features {
            margin-top: 40px;

            > * {
              &:not(:first-child) {
                margin-top: 10px;
              }
            }

            p {
              font-size: 14px;
              color: #000;
              font-weight: 400;
            }

            .select-wrapper-flexbox {
              display: flex;
              align-items: center;

              label {
                font-size: 14px;
                color: #000;
                letter-spacing: 0.8px;
                font-weight: 500;
                white-space: nowrap;
                margin-bottom: 0;
                margin-right: 20px;
              }

              .select-wrapper {
                max-width: 100%;
                width: auto;
              }
            }
          }

          .price {
            margin-top: 40px;

            .price-heading {
              font-size: 11px;
              color: #000;
              letter-spacing: 0.8px;
              font-weight: 500;
              text-transform: uppercase;
            }

            .price-brutto {
              color: #000;
              font-size: 28px;
              font-weight: 600;

              small {
                font-size: 17px;
                font-weight: 600;
                color: #444;
                margin-right: 5px;
                position: relative;
                display: none;

                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 1px;
                  width: 100%;
                  height: 1px;
                  background: #9999;
                  transform: rotate(-10deg);
                }
              }
            }

            .price-netto {
              font-weight: 400;
              color: #999;
              font-size: 15px;
            }

            .price-info {
              margin-top: 20px;
              font-size: 11px;
              color: #000;

              span {
                padding: 5px;
                border-radius: 3px;
                font-weight: 700;
                color: @color1;
                border: 1px solid @color1;

                small {
                  font-weight: 700;
                }
              }
            }

            .quantity-wrapper {
              margin-top: 30px;
              display: flex;
              align-items: center;

              .wrapper-inner {
                display: flex;
                margin-right: 10px;

                .input-number {
                  width: 60px;
                  padding: 0 12px;
                  margin: 0 10px;
                  font-size: 20px;
                  text-align: center;
                  outline: none;
                  border-radius: 3px;
                  border: none;
                }

                .input-number,
                .input-number-decrement,
                .input-number-increment {
                  user-select: none;
                  background: none;
                  border: 1px solid @color1;
                }

                .input-number-decrement,
                .input-number-increment {
                  color: @color1;
                  text-align: center;
                  font-weight: 900;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: background-color 0.4s;
                  cursor: pointer;
                  border: none;
                  outline: none;

                  &:active {
                    background-color: @color1;
                    color: #fff;
                  }
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  margin: 0;
                }

                input[type="number"] {
                  -moz-appearance: textfield;
                }
              }

              .btn-add {
                margin-left: 10px;
                display: flex;
                align-items: center;
                background: @color1;
                border-radius: 3px;
                padding: 10px 15px;

                svg {
                  min-width: 15px;
                  max-width: 15px;
                  height: 15px;
                  margin-right: 10px;
                  transition: all 0.4s;
                  fill: #fff;
                  border-radius: 0;
                }

                span {
                  font-size: 14px;
                  font-weight: 300;
                  transition: all 0.4s;
                  color: #fff;
                  border-left: 1px solid #f4f4f4;
                  padding-left: 10px;
                }

                &:hover {
                  background-color: darken(@color1, 10%);
                  color: #fff;
                }
              }
            }
          }

          .information {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 0.5px solid #ccc;
            display: flex;
            align-items: center;

            > * {
              &:not(:last-child) {
                margin-right: 30px;
              }
            }

            p {
              font-size: 14px;
              color: #000;
              letter-spacing: 0.8px;
              font-weight: 400;

              a {
                color: @color1;
              }
            }
          }

          .description {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 0.5px solid #ccc;

            p,
            li {
              font-size: 18px;
              color: #777;
              font-weight: 300;
              line-height: 32px;

              strong,
              b {
                font-weight: 700;
              }
            }

            ul,
            ol {
              padding-left: 17px;
            }
          }

          &.promotion {
            .promotion {
              display: flex !important;
            }

            .price-brutto {
              color: tomato !important;

              small {
                display: inline-block;
              }
            }
          }

          &.new {
            .new {
              display: flex !important;
            }

            .price-brutto {
              color: olivedrab;
            }
          }

          &.bestseller {
            .bestseller {
              display: flex !important;
            }

            .price-brutto {
              color: dodgerblue;
            }
          }
        }
      }

      .body-gallery {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .gallery-service {
          width: 12%;
          margin: 2%;
          display: block;

          img {
            width: 100%;
          }

          .service-hidden {
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

section.kontakt-section-content {
  .section-wrapper {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;

    .section-map {
      width: 65%;

      iframe {
        height: 410px;
        width: 100%;
      }
    }

    .section-contact {
      width: 30%;

      .service-logo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom: 30px;

        .logo {
          width: 35px;
          margin-right: 10px;
        }

        p {
          font-size: 55px;
          line-height: 55px;
          text-transform: uppercase;
          font-family: "Oswald", sans-serif;
				}
				
				span {
					font-family: "Oswald", sans-serif;
					font-size: 24px;
					display: block;
				}
      }

      address {
        margin: 15px 0 0 0;
        font-size: 15px;
        line-height: 30px;
        text-align: right;

        span {
          display: block;
          font-size: 14px;
          font-weight: 600;
        }
      }

      ul {
        li {
          text-align: right;

          &.heading {
            margin-top: 15px;
            font-size: 14px;
            font-weight: 600;
            text-align: right;
          }

          a {
            font-size: 15px;
            line-height: 30px;
            color: #000;
            transition: all 0.3s;

            &:hover {
              color: @color2;
            }
          }
        }
      }

      .list-social {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            svg {
              height: 25px;
              width: 25px;
              fill: #000;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

section.section-footer {
  margin-top: 60px;
  padding-top: 20px;
  background: @color1;
  position: relative;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    padding: 0 50px;

    .flexbox-service {
      margin-top: 50px;
      padding-right: 50px;

      &.map {
        width: 70%;

        iframe {
          margin-top: 5px;
          height: 300px;
          width: 100%;
        }
      }

      .service-logo {
        display: flex;
        align-items: center;

        .logo {
          width: 35px;
          margin-right: 10px;
        }

        h2 {
          font-size: 55px;
          line-height: 55px;
          color: #fff;
          font-weight: 400;
          text-transform: uppercase;
          font-family: "Oswald", sans-serif;
        }
      }

      address {
        margin: 30px 0 20px 0;
        color: #fff;
        font-weight: 300;
        font-size: 15px;

        span {
          color: #f2f4f4;
          font-weight: 300;
          opacity: 0.5;
          font-size: 20px;
          margin: 0 0 15px 0;
          display: block;
        }
      }

      ul {
        li {
          margin-top: 5px;

          &.heading {
            color: #f2f4f4;
            font-weight: 300;
            opacity: 0.5;
            font-size: 20px;
            margin: 0 0 10px 0;
          }

          a {
            font-size: 15px;
            color: #fff;
            font-weight: 300;
            transition: all 0.3s;

            &:hover {
              color: @color2;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            svg {
              height: 25px;
              width: 25px;
              fill: #000;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
			}
    }
  }

  .alert {
    padding: 25px 0;
    margin: 0;
    position: relative;
    background: #011418;
    border-radius: 0;
    border: none;
    text-align: center;

    p {
      font-size: 11px;
      color: #fff;
      font-weight: 300;
    }
  }

  .footer-credits {
    padding: 0 50px;
    margin: 60px 0 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 11px;
      opacity: 0.6;
      color: #fff;
      font-weight: 300;

      a {
        color: inherit;
        font-weight: 500;
      }
		}
					
		.logo-payment {
			width: 100px;
		}
  }
}
