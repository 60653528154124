// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	.products-flexbox {
		.product-service {
			.btn-more {
				p {
					font-size: 12px;
				}
			}
		}
	}

	nav.section-header {
		.header-management {
			.management-search-engine {
				.search-wrapper {
					width: 200px;
				}
			}
		}

		.header-flexbox {
			.flexbox-nav-wrapper {
				.flexbox-nav-inner {
					.flexbox-nav {
						li {
							a {
								font-size: 13px;
							}

							&:not(:last-child) {
								margin-right: 15px;
							}
						}
					}
				}
			}
		}
	}

	section.section-offer {
		.offer-content {
			.category {
				padding: 0;
			}
		}
	}

	section.section-footer {
		margin-top: 0;

		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 20px;
				padding-right: 0;

				&:nth-of-type(2) {
					display: none;
				}

				.list-social {
					justify-content: center;
				}
			}
		}

		.footer-credits {
			margin: 30px 0;
			text-align: center;
			justify-content: center;
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	nav.section-header {
		.header-management {
			width: 100%;

			&.mobile {
				display: flex;
				margin-top: 0;

				.management-search-engine {
					.search-wrapper {
						width: 300px;
					}
				}
			}

			&.desktop {
				display: none;
			}
		}

		.header-top {
			width: 100%;

			.top-service {
				flex-direction: column;
				align-items: flex-end;
				width: 100%;
			}

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-logo {
				&.desktop {
					display: none;
				}

				&.mobile {
					display: flex;
					margin-right: 20px;

					img {
						width: 25px;
					}

					p {
						font-size: 36px;
					}
				}
			}

			.top-navigation {
				.divider {
					margin: 0 12px;
				}

				li {
					margin: 0 5px;
				}
			}
		}

		.header-flexbox {
			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;
				padding: 0;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						display: flex;
						flex-direction: column;

						li {
							margin: 0 !important;
							padding: 8px 0;

							&.nav-link-mobile {
								display: block;
							}

							&.contact {
								display: block;
							}

							a {
								font-size: 15px;
								font-weight: 300;
								text-transform: capitalize;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.main-section-heading {
		.heading-content {
			width: 55%;

			h1 {
				font-size: 38px;
				line-height: 40px;
			}
		}

		.heading-image {
			position: relative;
			top: 30px;
			width: 35%;
			padding-top: 25%;
		}
	}

	section.section-offer {
		.section-heading {
			padding-top: 40px;

			p {
				padding: 0 5%;
				font-size: 18px;
			}
		}

		.offer-content {
			padding-top: 30px;
			flex-direction: column;
			align-items: center;

			.category {
				margin-top: 15px;
				width: 80%;
				height: 150px;
			}
		}
	}

	section.section-bestseller {
		.owl-carousel-products {
			.item {

				.product-image {
					width: 40%;
				}

				.product-description {
					width: 55%;
				}
			}
		}
	}

	.products-flexbox {
		.product-service {
			width: 30%;
		}

		&.full-width {
			.product-service {
				width: 23%;
			}
		}
	}

	section.kategoria-section-content {
		padding: 0 0 35px 0;

		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.category-nav-button {
				display: flex;
				justify-content: center;
			}

			.flexbox-sidebar {
				width: 100%;

				.heading:first-of-type {
					display: none;
				}

				.nav-button {
					display: block;
				}

				.category-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
					}

					.category-nav-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						min-height: 100%;
						padding: 80px 50px 50px 50px;

						.category-nav {
							li {
								ul {
									padding-left: 0;
								}
							}
						}

						.filter-nav {

							.heading {
								display: block;

								&:not(:first-of-type) {
									margin-top: 30px;
								}
							}

							ul {
								li {
									padding: 8px 0;
								}
							}

							.checkbox-wrapper {
								.radio-box {
									justify-content: center;
								}
							}
	
							.buttons-wrapper {
								margin-top: 40px;
							}
						}
					}
				}
			}

			.flexbox-body {
				width: 100%;
				margin-top: 20px;

				.heading {
					font-size: 38px;
				}

				.body-product {
					display: flex;
					flex-direction: column;
					align-items: center;

					.product-gallery {
						margin-top: 0;
						width: 70%;
					}

					.product-info {
						width: 100%;
						margin-top: 30px;
						text-align: center;

						h1 {
							font-size: 28px;
						}

						.features {
							.select-wrapper-flexbox {
								justify-content: center;
							}
						}

						.badge-wrapper {
							justify-content: center;
						}

						.price {
							margin-top: 20px;

							.price-info {
								span {
									display: block;
									width: 50%;
									margin: 10px auto;
								}
							}

							.quantity-wrapper {
								margin-top: 30px;
								flex-direction: column;
								align-items: center;

								.btn-add {
									margin-left: 0;
									margin-top: 20px;
								}
							}
						}

						.information {
							justify-content: center;
							flex-direction: column;
							padding-top: 20px;

							>* {
								&:not(:last-child) {
									margin-right: 0;
								}
							}

							p {
								margin: 5px 0;
								text-align: center;
							}
						}

						.description {
							margin-top: 20px;
							text-align: left;

							ul,
							ol {
								padding-left: 17px;
								text-align: left;
							}
						}
					}
				}

				.body-gallery {
					.gallery-service {
						width: 28%;
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.btn {
		&.btn-default {
			padding: 12px 50px;
		}
	}

	.main-section-heading {
		padding: 40px 0;

		.heading-content {
			width: 100%;

			p {
				font-size: 15px;
			}
		}

		.heading-image {
			display: none;
		}
	}

	.products-flexbox {
		&.full-width {
			.product-service {
				width: 46%;
			}
        }
    }

	.breadcrumb-wrapper {
		margin-bottom: 20px;

		.breadcrumb {
			padding: 0;
		}
	}

	nav.section-header {
		.header-top {
			.top-service {
				.top-navigation {
					width: 100%;
					justify-content: flex-end;
	
					&.mobile {
						display: flex;
						justify-content: space-between;

						&:nth-of-type(2) {
							padding: 5px 0;
							justify-content: center;
						}
					}

					&.desktop {
						display: none;
					}
	
					.divider {
						display: none;
					}
				}
			}
		}
	}

	section.section-welcome {
		padding-bottom: 0;
	}

	section.section-bestseller {
		padding: 60px 0 30px 0;

		.owl-carousel-products {
			height: auto;

			.owl-item {
				height: auto;
			}

			.item {
				height: auto;

				footer {
					margin-top: 30px;
				}

				.product-image {
					display: none;
				}

				.product-description {
					padding: 30px 20px;
					width: 100%;

					h3 {
						font-size: 28px;
					}

					p {
						font-size: 15px;
					}

					.price {
						font-size: 24px;

						span {
							font-size: 20px;
						}
					}

					.btn-default {
						margin-top: 30px;
					}
				}
			}
		}
	}

	section.section-cooperate {
		padding-top: 60px;
	}

	section.kontakt-section-content {
		.section-wrapper {
			padding: 40px 0;
			flex-direction: column;
			align-items: center;

			.section-map {
				margin-top: 30px;
				order: 2;
				width: 100%;

				iframe {
					height: 250px;
				}
			}

			.section-contact {
				order: 1;
				width: 100%;

				.service-logo {
					justify-content: center;
				}

				address {
					text-align: center;
				}

				ul {
					li {
						text-align: center;

						&.heading {
							text-align: center;
						}
					}
				}

				.list-social {
					justify-content: center;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.btn {
		font-size: 12px;

		&.btn-default {
			padding: 12px 25px;
		}
	}

	.products-flexbox {

		&.relative {    
				.category-img-absolute {
						display: none;
				}
		}
        
		.product-service {
			margin-top: calc(15% / 3);
			width: 46%;

			.price {
				font-size: 17px;
			}
		}
	}

	section.static-page-section-content {
		.section-description {
			margin-top: 40px;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
	.section-heading {
		h2 {
			font-size: 42px;
		}
	}

	nav.section-header {
		.header-top {
			.top-navigation {
				&.mobile {
					justify-content: space-between;
				}
			}
		}

		.header-management {
			.management-search-engine {
				.search-wrapper {
					.sb-search {
						.sb-search-input {
							&::placeholder {
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
}