@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body h1,
body h2,
body h3,
body h4 {
  font-family: "Oswald", sans-serif;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.breadcrumb-wrapper {
  margin-bottom: 40px;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  background: none;
  margin-bottom: 0;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 11px;
  font-weight: 400;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  color: #022127;
  transition: all 0.4s;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a svg {
  display: none;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a:hover {
  color: #000000;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item.active {
  color: #e93333;
  font-weight: 600;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-weight: 200;
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.products-flexbox.relative {
  position: relative;
}
.products-flexbox.relative .category-img-absolute {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 60%);
  opacity: 0.5;
}
.products-flexbox .product-service {
  margin-top: calc(8% / 3);
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
}
.products-flexbox .product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 0;
  right: 0;
}
.products-flexbox .product-service .badge-wrapper .badge {
  width: 70px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}
.products-flexbox .product-service .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
.products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 70%;
  border-radius: 3px;
  display: block;
  transition: all 0.4s;
  background: #fff;
  position: relative;
}
.products-flexbox .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
.products-flexbox .product-service .service-image-wrapper .service-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.products-flexbox .product-service .service-image-wrapper.active:after {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: #d30e0e;
  box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
}
.products-flexbox .product-service .name {
  margin-top: 20px;
  color: #222;
  font-weight: 600;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox .product-service .name.list-view {
  display: none;
}
.products-flexbox .product-service .type {
  margin-top: 5px;
  font-size: 11px;
  font-weight: 500;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.products-flexbox .product-service .price {
  margin-top: 10px;
  color: #022127;
  font-size: 22px;
  font-weight: 600;
}
.products-flexbox .product-service .price small {
  font-size: 15px;
  font-weight: 600;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #999;
  transform: rotate(-10deg);
}
.products-flexbox .product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.products-flexbox .product-service .price-small {
  font-size: 13px;
  color: #999;
}
.products-flexbox .product-service .btn-more {
  margin-top: 17px;
  width: 100%;
  display: flex;
  align-items: center;
}
.products-flexbox .product-service .btn-more p {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  border-radius: 3px;
  transition: all 0.4s;
}
.products-flexbox .product-service .btn-more p:nth-child(1) {
  min-width: 25px;
  border-right: 1px solid #f4f4f4;
  border-radius: 0;
  padding-right: 10px;
}
.products-flexbox .product-service .btn-more p:nth-child(2) {
  width: 100%;
  margin-left: 7px;
}
.products-flexbox .product-service.promotion .promotion {
  display: block !important;
}
.products-flexbox .product-service.promotion .price {
  color: tomato!important;
}
.products-flexbox .product-service.promotion .price small {
  display: inline-block;
}
.products-flexbox .product-service.new .new {
  display: block !important;
}
.products-flexbox .product-service.new .price {
  color: olivedrab;
}
.products-flexbox .product-service.bestseller .bestseller {
  display: block !important;
}
.products-flexbox .product-service.bestseller .price {
  color: dodgerblue;
}
.products-flexbox .product-service:hover .service-image-wrapper {
  opacity: 0.8;
}
.products-flexbox .product-service:hover .name {
  color: #e93333;
}
.products-flexbox .product-service:hover .btn-more {
  background-color: #000;
}
.products-flexbox .product-service:hover .btn-more p {
  color: #fff;
}
.products-flexbox .product-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.grid-list .product-service {
  width: 100% !important;
  flex-direction: row;
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service header {
  min-width: 17%;
  margin-right: 15px;
}
.products-flexbox.grid-list .product-service .badge-wrapper {
  right: auto;
  left: 0;
  top: 10px;
}
.products-flexbox.grid-list .product-service .service-image-wrapper.active:after {
  left: auto;
  right: 0;
}
.products-flexbox.grid-list .product-service .price {
  position: absolute;
  right: 0;
  top: 30px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .price-small {
  position: absolute;
  right: 0;
  top: 55px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 0;
}
.products-flexbox.grid-list .product-service .name.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .name.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service:not(:first-child) {
  padding-top: 30px;
  border-top: 1px solid #f5f5f5;
}
.products-flexbox.full-width {
  margin-top: calc(-5% / 2);
}
.products-flexbox.full-width .product-service {
  margin-top: calc(5% / 2);
  width: 22%;
}
.pagination-nav {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  color: #777;
  text-transform: uppercase;
  transition: all 0.3s;
}
.pagination-nav li:hover a {
  color: #022127;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #fff;
  background: #022127;
}
.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
.checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 300;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/icons/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper > input:checked + .radio-box {
  color: #022127;
  font-weight: 500;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/icons/checkbox-checked.svg") no-repeat;
}
.select-wrapper {
  position: relative;
  min-width: 180px;
  border-radius: 3px;
  border: 0.5px solid #022127;
}
.select-wrapper select {
  cursor: pointer;
  padding: 7px 10px;
  border: none;
  background: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  font-size: 12px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
}
.select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 5px;
  width: 5px;
  background: url("../img/icons/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-40%);
}
.modal img {
  width: 100%;
}
.btn {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}
.btn.btn-default {
  padding: 12px 80px;
  background-color: #022127;
}
.btn.btn-default:hover {
  background-color: #000000;
  color: #fff;
}
.btn.btn-more {
  padding: 10px 15px;
  background-color: #022127;
}
.btn.btn-more:hover {
  background-color: #000000;
  color: #fff;
}
nav.section-header .header-top {
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-top .flexbox-logo {
  display: flex;
}
nav.section-header .header-top .flexbox-logo.mobile {
  display: none;
}
nav.section-header .header-top .flexbox-logo p {
  font-size: 48px;
  color: #000;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}
nav.section-header .header-top .flexbox-logo img {
  margin-right: 10px;
}
nav.section-header .header-top .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-top .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #e93333;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-top .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-top .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-top .nav-button:hover .button-bar,
nav.section-header .header-top .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .header-top .top-service {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-navigation {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-navigation.mobile {
  display: none;
}
nav.section-header .header-top .top-navigation .divider {
  width: 1px;
  height: 25px;
  margin: 0 20px;
  display: block;
  background-color: #ccc;
}
nav.section-header .header-top .top-navigation li {
  display: flex;
  margin: 0 12px;
}
nav.section-header .header-top .top-navigation li:first-child img {
  width: 18px;
}
nav.section-header .header-top .top-navigation li a {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}
nav.section-header .header-top .top-navigation li a p {
  font-size: 10px;
}
nav.section-header .header-top .top-navigation li a:hover {
  color: #e93333;
}
nav.section-header .header-top .top-navigation li img {
  margin-right: 10px;
  width: 25px;
}
nav.section-header .header-management-wrapper {
  background-color: #f2f4f4;
}
nav.section-header .header-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
nav.section-header .header-management.mobile {
  display: none;
}
nav.section-header .header-management .management-search-engine {
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-management .management-search-engine .search-wrapper {
  width: 250px;
}
nav.section-header .header-management .management-search-engine .search-wrapper form {
  height: 100%;
  display: flex;
}
nav.section-header .header-management .management-search-engine .search-wrapper form .btn-search {
  border: 0;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
nav.section-header .header-management .management-search-engine .search-wrapper form .btn-search img {
  margin-right: 10px;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search {
  position: relative;
  width: 100%;
  min-width: 40px;
  height: 40px;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search:before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #000;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search .focus-border {
  position: absolute;
  z-index: 1;
  display: block;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.5px;
  background-color: #e93333;
  transition: 0.4s;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search:focus {
  outline: none;
  border-bottom: 1px solid #022127;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search .sb-search-input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 100%;
  padding: 0 0 10px 0;
  border-radius: 0;
  color: #000;
  opacity: 0.6;
  transition: all 0.4s;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search .sb-search-input::placeholder {
  font-size: 15px;
  font-weight: 300;
}
nav.section-header .header-management .management-search-engine .search-wrapper .sb-search .sb-search-input:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
nav.section-header .header-flexbox {
  position: relative;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  padding: 10px 0 20px 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  text-align: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-link-mobile {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-link-mobile:last-of-type a {
  font-weight: 600;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  font-size: 14px;
  transition: all 0.4s;
  color: #000;
  font-weight: 600;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #e93333;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact {
  padding-top: 20px;
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact a {
  color: #e93333;
  font-weight: 500;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active a {
  font-weight: 600;
  color: #e93333;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:not(:last-child) {
  margin-right: 20px;
}
.welcome-wrapper {
  background-color: #f2f4f4;
}
.main-section-heading {
  background-color: #f2f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-section-heading .heading-content {
  position: relative;
  margin-left: 100px;
  width: 55%;
}
.main-section-heading .heading-content .color-point {
  position: absolute;
  left: -100px;
  height: 100%;
  width: 70px;
  background: linear-gradient(167deg, #ff3333 15%, #c33333 84%);
}
.main-section-heading .heading-content h1 {
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 15px;
  font-weight: 400;
}
.main-section-heading .heading-content p {
  font-size: 20px;
  font-weight: 300;
}
.main-section-heading .heading-image {
  position: relative;
  top: 30px;
  width: 45%;
  padding-top: 25%;
}
.main-section-heading .heading-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.section-heading {
  text-align: center;
}
.section-heading h2 {
  font-size: 48px;
  margin-bottom: 15px;
  font-weight: 400;
}
.section-heading p {
  font-size: 20px;
  font-weight: 300;
  padding: 0 10%;
  color: #777;
}
.section-heading .btn-default {
  margin-top: 40px;
}
section.section-welcome {
  padding-bottom: 40px;
  overflow: hidden;
}
section.static-page-section-content {
  padding-bottom: 60px;
}
section.static-page-section-content .section-description {
  margin-top: 80px;
}
section.static-page-section-content .section-description p {
  color: #777;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}
section.static-page-section-content .section-description p:not(:first-of-type) {
  margin-top: 15px;
}
section.static-page-section-content .section-description ul {
  padding: 10px 0 0 20px;
}
section.static-page-section-content .section-description ul li {
  padding: 8px 0;
  color: #777;
  font-weight: 300;
  font-size: 18px;
}
section.section-offer .section-heading {
  padding-top: 70px;
}
section.section-offer .section-heading:first-of-type {
  padding-top: 30px;
}
section.section-offer .offer-content {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}
section.section-offer .offer-content .category {
  width: calc(100% / 7 - 15px);
  height: 200px;
  background-color: #f2f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: background-color 350ms;
  padding: 0 15px;
}
section.section-offer .offer-content .category h3 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  color: #000;
  transition: color 350ms;
}
section.section-offer .offer-content .category:hover {
  background-color: #e7ebeb;
}
section.section-offer .offer-content .category:hover h3 {
  color: #e93333;
}
section.section-bestseller {
  padding-top: 70px;
}
section.section-bestseller .owl-carousel-products {
  height: 450px;
}
section.section-bestseller .owl-carousel-products .owl-stage-outer,
section.section-bestseller .owl-carousel-products .owl-stage,
section.section-bestseller .owl-carousel-products .owl-item {
  height: 100%;
}
section.section-bestseller .owl-carousel-products .item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
section.section-bestseller .owl-carousel-products .item .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 0;
  right: 0;
}
section.section-bestseller .owl-carousel-products .item .badge-wrapper .badge {
  width: 100px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
}
section.section-bestseller .owl-carousel-products .item .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
section.section-bestseller .owl-carousel-products .item .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
section.section-bestseller .owl-carousel-products .item .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
section.section-bestseller .owl-carousel-products .item.promotion .promotion {
  display: flex !important;
}
section.section-bestseller .owl-carousel-products .item.promotion .price {
  color: tomato !important;
}
section.section-bestseller .owl-carousel-products .item.promotion .price small {
  display: inline-block !important;
}
section.section-bestseller .owl-carousel-products .item.new .new {
  display: flex !important;
}
section.section-bestseller .owl-carousel-products .item.new .price {
  color: olivedrab;
}
section.section-bestseller .owl-carousel-products .item.bestseller .bestseller {
  display: flex !important;
}
section.section-bestseller .owl-carousel-products .item.bestseller .price {
  color: dodgerblue;
}
section.section-bestseller .owl-carousel-products .item .product-image {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
section.section-bestseller .owl-carousel-products .item .product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.section-bestseller .owl-carousel-products .item .product-description {
  width: 45%;
  height: 100%;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}
section.section-bestseller .owl-carousel-products .item .product-description h3 {
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 10px;
}
section.section-bestseller .owl-carousel-products .item .product-description p {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  color: #777;
}
section.section-bestseller .owl-carousel-products .item .product-description .price {
  font-family: "Oswald", sans-serif;
  color: #022127;
  font-size: 48px;
  font-weight: 400;
}
section.section-bestseller .owl-carousel-products .item .product-description .price small {
  font-size: 22px;
  font-weight: 600;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
section.section-bestseller .owl-carousel-products .item .product-description .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -3px;
  width: 115%;
  height: 1px;
  background: #aaa;
  transform: rotate(-10deg);
}
section.section-bestseller .owl-carousel-products .item .product-description .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
section.section-bestseller .owl-carousel-products .item .product-description .price-small {
  font-size: 13px;
  color: #999;
}
section.section-bestseller .owl-carousel-products .item .product-description .btn-default {
  margin-top: 50px;
}
section.section-cooperate {
  padding-top: 70px;
}
section.section-cooperate .owl-carousel {
  margin: 40px 0;
}
section.section-cooperate .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
section.section-cooperate .owl-carousel .owl-controls {
  display: none;
}
section.kategoria-section-content {
  padding: 0 0 35px 0;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 20%;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
  margin-bottom: 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading h2 {
  font-size: 20px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 500;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
  outline: none;
  padding: 10px;
  width: 100%;
  border: none;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  background: #022127;
  transition: all 0.3s;
  display: none;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button:hover {
  background: #022127;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper.active {
  opacity: 1;
  visibility: visible;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:before,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:after {
  content: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav > li > ul {
  margin-top: 5px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
  list-style: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
  font-size: 15px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 300;
  transition: all 0.4s;
  display: block;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a:hover {
  color: #e93333;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li.active > a {
  font-weight: 500;
  color: #e93333;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li:not(:last-child) {
  padding-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li {
  padding-bottom: 0 !important;
  border-bottom: none !important;
  margin-bottom: 0 !important;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
  padding: 0.25rem 0;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  color: #777;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
  margin-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading:not(:first-of-type) {
  margin-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"] {
  border: none;
  margin: 0 10px;
  outline: none;
  text-align: center;
  font-size: 14px;
  height: 30px;
  border: 1px solid #022127;
  border-radius: 3px;
  width: 100%;
  max-width: 65px;
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-outer-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:invalid,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:out-of-range {
  border: 1px solid #ff6347;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper span {
  display: block;
  min-width: 12px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper p {
  font-size: 13px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .select-wrapper {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: none;
  color: #000;
  text-align: left;
  transition: all 0.3s;
  padding: 5px 0;
  font-weight: 500;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/icons/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box p,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box span {
  font-size: 11px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/icons/checkbox-checked.svg") no-repeat;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-clear {
  color: #000;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  width: 72%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
  font-size: 11px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
  margin-left: 30px;
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button {
  margin-right: 7px;
  padding: 0;
  border: none;
  background: #fff;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button img {
  width: 15px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:last-child,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:last-child {
  margin-right: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active {
  background: #022127;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading {
  font-family: "Oswald", sans-serif;
  margin-top: 70px;
  margin-bottom: 30px;
  text-align: center;
  color: #000;
  font-weight: 400;
  font-size: 48px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
  width: 30%;
  position: relative;
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery img {
  width: 100%;
  border-radius: 3px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
  width: 60%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper {
  display: flex;
  z-index: 111;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge {
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 3px;
  transition: all 0.4s;
  margin-bottom: 35px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge:not(:last-child) {
  margin-right: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h1 {
  color: #000;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description {
  margin-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description p,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description li {
  font-size: 12px;
  color: #777;
  font-weight: 300;
  line-height: 22px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description p strong,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description li strong,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description p b,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description li b {
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description ul,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .small-description ol {
  padding-left: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features {
  margin-top: 40px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features > *:not(:first-child) {
  margin-top: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox label {
  font-size: 14px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 0;
  margin-right: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper {
  max-width: 100%;
  width: auto;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
  margin-top: 40px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-heading {
  font-size: 11px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto {
  color: #000;
  font-size: 28px;
  font-weight: 600;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto small {
  font-size: 17px;
  font-weight: 600;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #9999;
  transform: rotate(-10deg);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto {
  font-weight: 400;
  color: #999;
  font-size: 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-info {
  margin-top: 20px;
  font-size: 11px;
  color: #000;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-info span {
  padding: 5px;
  border-radius: 3px;
  font-weight: 700;
  color: #022127;
  border: 1px solid #022127;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-info span small {
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
  display: flex;
  margin-right: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number {
  width: 60px;
  padding: 0 12px;
  margin: 0 10px;
  font-size: 20px;
  text-align: center;
  outline: none;
  border-radius: 3px;
  border: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  user-select: none;
  background: none;
  border: 1px solid #022127;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  color: #022127;
  text-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement:active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background-color: #022127;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn-add {
  margin-left: 10px;
  display: flex;
  align-items: center;
  background: #022127;
  border-radius: 3px;
  padding: 10px 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn-add svg {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  margin-right: 10px;
  transition: all 0.4s;
  fill: #fff;
  border-radius: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn-add span {
  font-size: 14px;
  font-weight: 300;
  transition: all 0.4s;
  color: #fff;
  border-left: 1px solid #f4f4f4;
  padding-left: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn-add:hover {
  background-color: #000000;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 0.5px solid #ccc;
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information > *:not(:last-child) {
  margin-right: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information p {
  font-size: 14px;
  color: #000;
  letter-spacing: 0.8px;
  font-weight: 400;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information p a {
  color: #022127;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 0.5px solid #ccc;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description li {
  font-size: 18px;
  color: #777;
  font-weight: 300;
  line-height: 32px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p strong,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description li strong,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p b,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description li b {
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ul,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ol {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .promotion {
  display: flex !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .price-brutto {
  color: tomato !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .price-brutto small {
  display: inline-block;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.new .new {
  display: flex !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.new .price-brutto {
  color: olivedrab;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.bestseller .bestseller {
  display: flex !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.bestseller .price-brutto {
  color: dodgerblue;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-gallery .gallery-service {
  width: 12%;
  margin: 2%;
  display: block;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-gallery .gallery-service img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-gallery .gallery-service .service-hidden {
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}
section.kontakt-section-content .section-wrapper {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .section-wrapper .section-map {
  width: 65%;
}
section.kontakt-section-content .section-wrapper .section-map iframe {
  height: 410px;
  width: 100%;
}
section.kontakt-section-content .section-wrapper .section-contact {
  width: 30%;
}
section.kontakt-section-content .section-wrapper .section-contact .service-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 30px;
}
section.kontakt-section-content .section-wrapper .section-contact .service-logo .logo {
  width: 35px;
  margin-right: 10px;
}
section.kontakt-section-content .section-wrapper .section-contact .service-logo p {
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}
section.kontakt-section-content .section-wrapper .section-contact .service-logo span {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  display: block;
}
section.kontakt-section-content .section-wrapper .section-contact address {
  margin: 15px 0 0 0;
  font-size: 15px;
  line-height: 30px;
  text-align: right;
}
section.kontakt-section-content .section-wrapper .section-contact address span {
  display: block;
  font-size: 14px;
  font-weight: 600;
}
section.kontakt-section-content .section-wrapper .section-contact ul li {
  text-align: right;
}
section.kontakt-section-content .section-wrapper .section-contact ul li.heading {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
section.kontakt-section-content .section-wrapper .section-contact ul li a {
  font-size: 15px;
  line-height: 30px;
  color: #000;
  transition: all 0.3s;
}
section.kontakt-section-content .section-wrapper .section-contact ul li a:hover {
  color: #e93333;
}
section.kontakt-section-content .section-wrapper .section-contact .list-social {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.kontakt-section-content .section-wrapper .section-contact .list-social li {
  margin-right: 20px;
}
section.kontakt-section-content .section-wrapper .section-contact .list-social li a svg {
  height: 25px;
  width: 25px;
  fill: #000;
}
section.kontakt-section-content .section-wrapper .section-contact .list-social li:last-child {
  margin-right: 0;
}
section.section-footer {
  margin-top: 60px;
  padding-top: 20px;
  background: #022127;
  position: relative;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  padding: 0 50px;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 50px;
  padding-right: 50px;
}
section.section-footer .footer-flexbox .flexbox-service.map {
  width: 70%;
}
section.section-footer .footer-flexbox .flexbox-service.map iframe {
  margin-top: 5px;
  height: 300px;
  width: 100%;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo {
  display: flex;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo .logo {
  width: 35px;
  margin-right: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo h2 {
  font-size: 55px;
  line-height: 55px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}
section.section-footer .footer-flexbox .flexbox-service address {
  margin: 30px 0 20px 0;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
}
section.section-footer .footer-flexbox .flexbox-service address span {
  color: #f2f4f4;
  font-weight: 300;
  opacity: 0.5;
  font-size: 20px;
  margin: 0 0 15px 0;
  display: block;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  color: #f2f4f4;
  font-weight: 300;
  opacity: 0.5;
  font-size: 20px;
  margin: 0 0 10px 0;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 15px;
  color: #fff;
  font-weight: 300;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #e93333;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a svg {
  height: 25px;
  width: 25px;
  fill: #000;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  padding: 25px 0;
  margin: 0;
  position: relative;
  background: #011418;
  border-radius: 0;
  border: none;
  text-align: center;
}
section.section-footer .alert p {
  font-size: 11px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-credits {
  padding: 0 50px;
  margin: 60px 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-footer .footer-credits p {
  font-size: 11px;
  opacity: 0.6;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: inherit;
  font-weight: 500;
}
section.section-footer .footer-credits .logo-payment {
  width: 100px;
}
@media (max-width: 1199.98px) {
  .products-flexbox .product-service .btn-more p {
    font-size: 12px;
  }
  nav.section-header .header-management .management-search-engine .search-wrapper {
    width: 200px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 13px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:not(:last-child) {
    margin-right: 15px;
  }
  section.section-offer .offer-content .category {
    padding: 0;
  }
  section.section-footer {
    margin-top: 0;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 20px;
    padding-right: 0;
  }
  section.section-footer .footer-flexbox .flexbox-service:nth-of-type(2) {
    display: none;
  }
  section.section-footer .footer-flexbox .flexbox-service .list-social {
    justify-content: center;
  }
  section.section-footer .footer-credits {
    margin: 30px 0;
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-management {
    width: 100%;
  }
  nav.section-header .header-management.mobile {
    display: flex;
    margin-top: 0;
  }
  nav.section-header .header-management.mobile .management-search-engine .search-wrapper {
    width: 300px;
  }
  nav.section-header .header-management.desktop {
    display: none;
  }
  nav.section-header .header-top {
    width: 100%;
  }
  nav.section-header .header-top .top-service {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
  nav.section-header .header-top .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-top .flexbox-logo.desktop {
    display: none;
  }
  nav.section-header .header-top .flexbox-logo.mobile {
    display: flex;
    margin-right: 20px;
  }
  nav.section-header .header-top .flexbox-logo.mobile img {
    width: 25px;
  }
  nav.section-header .header-top .flexbox-logo.mobile p {
    font-size: 36px;
  }
  nav.section-header .header-top .top-navigation .divider {
    margin: 0 12px;
  }
  nav.section-header .header-top .top-navigation li {
    margin: 0 5px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    padding: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    display: flex;
    flex-direction: column;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin: 0 !important;
    padding: 8px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-link-mobile {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.contact {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 15px;
    font-weight: 300;
    text-transform: capitalize;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .main-section-heading .heading-content {
    width: 55%;
  }
  .main-section-heading .heading-content h1 {
    font-size: 38px;
    line-height: 40px;
  }
  .main-section-heading .heading-image {
    position: relative;
    top: 30px;
    width: 35%;
    padding-top: 25%;
  }
  section.section-offer .section-heading {
    padding-top: 40px;
  }
  section.section-offer .section-heading p {
    padding: 0 5%;
    font-size: 18px;
  }
  section.section-offer .offer-content {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  section.section-offer .offer-content .category {
    margin-top: 15px;
    width: 80%;
    height: 150px;
  }
  section.section-bestseller .owl-carousel-products .item .product-image {
    width: 40%;
  }
  section.section-bestseller .owl-carousel-products .item .product-description {
    width: 55%;
  }
  .products-flexbox .product-service {
    width: 30%;
  }
  .products-flexbox.full-width .product-service {
    width: 23%;
  }
  section.kategoria-section-content {
    padding: 0 0 35px 0;
  }
  section.kategoria-section-content .content-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .category-nav-button {
    display: flex;
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading:first-of-type {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 80px 50px 50px 50px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
    padding-left: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading:not(:first-of-type) {
    margin-top: 30px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav ul li {
    padding: 8px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper {
    margin-top: 40px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 100%;
    margin-top: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .heading {
    font-size: 38px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
    margin-top: 0;
    width: 70%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h1 {
    font-size: 28px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
    margin-top: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-info span {
    display: block;
    width: 50%;
    margin: 10px auto;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn-add {
    margin-left: 0;
    margin-top: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information {
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information > *:not(:last-child) {
    margin-right: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .information p {
    margin: 5px 0;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description {
    margin-top: 20px;
    text-align: left;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ul,
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ol {
    padding-left: 17px;
    text-align: left;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-gallery .gallery-service {
    width: 28%;
  }
}
@media (max-width: 767.98px) {
  .btn.btn-default {
    padding: 12px 50px;
  }
  .main-section-heading {
    padding: 40px 0;
  }
  .main-section-heading .heading-content {
    width: 100%;
  }
  .main-section-heading .heading-content p {
    font-size: 15px;
  }
  .main-section-heading .heading-image {
    display: none;
  }
  .products-flexbox.full-width .product-service {
    width: 46%;
  }
  .breadcrumb-wrapper {
    margin-bottom: 20px;
  }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0;
  }
  nav.section-header .header-top .top-service .top-navigation {
    width: 100%;
    justify-content: flex-end;
  }
  nav.section-header .header-top .top-service .top-navigation.mobile {
    display: flex;
    justify-content: space-between;
  }
  nav.section-header .header-top .top-service .top-navigation.mobile:nth-of-type(2) {
    padding: 5px 0;
    justify-content: center;
  }
  nav.section-header .header-top .top-service .top-navigation.desktop {
    display: none;
  }
  nav.section-header .header-top .top-service .top-navigation .divider {
    display: none;
  }
  section.section-welcome {
    padding-bottom: 0;
  }
  section.section-bestseller {
    padding: 60px 0 30px 0;
  }
  section.section-bestseller .owl-carousel-products {
    height: auto;
  }
  section.section-bestseller .owl-carousel-products .owl-item {
    height: auto;
  }
  section.section-bestseller .owl-carousel-products .item {
    height: auto;
  }
  section.section-bestseller .owl-carousel-products .item footer {
    margin-top: 30px;
  }
  section.section-bestseller .owl-carousel-products .item .product-image {
    display: none;
  }
  section.section-bestseller .owl-carousel-products .item .product-description {
    padding: 30px 20px;
    width: 100%;
  }
  section.section-bestseller .owl-carousel-products .item .product-description h3 {
    font-size: 28px;
  }
  section.section-bestseller .owl-carousel-products .item .product-description p {
    font-size: 15px;
  }
  section.section-bestseller .owl-carousel-products .item .product-description .price {
    font-size: 24px;
  }
  section.section-bestseller .owl-carousel-products .item .product-description .price span {
    font-size: 20px;
  }
  section.section-bestseller .owl-carousel-products .item .product-description .btn-default {
    margin-top: 30px;
  }
  section.section-cooperate {
    padding-top: 60px;
  }
  section.kontakt-section-content .section-wrapper {
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    margin-top: 30px;
    order: 2;
    width: 100%;
  }
  section.kontakt-section-content .section-wrapper .section-map iframe {
    height: 250px;
  }
  section.kontakt-section-content .section-wrapper .section-contact {
    order: 1;
    width: 100%;
  }
  section.kontakt-section-content .section-wrapper .section-contact .service-logo {
    justify-content: center;
  }
  section.kontakt-section-content .section-wrapper .section-contact address {
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-contact ul li {
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-contact ul li.heading {
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-contact .list-social {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .btn {
    font-size: 12px;
  }
  .btn.btn-default {
    padding: 12px 25px;
  }
  .products-flexbox.relative .category-img-absolute {
    display: none;
  }
  .products-flexbox .product-service {
    margin-top: calc(15% / 3);
    width: 46%;
  }
  .products-flexbox .product-service .price {
    font-size: 17px;
  }
  section.static-page-section-content .section-description {
    margin-top: 40px;
  }
}
@media (max-width: 400px) {
  .section-heading h2 {
    font-size: 42px;
  }
  nav.section-header .header-top .top-navigation.mobile {
    justify-content: space-between;
  }
  nav.section-header .header-management .management-search-engine .search-wrapper .sb-search .sb-search-input::placeholder {
    font-size: 13px;
  }
}
